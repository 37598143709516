import React from 'react';
import { Form, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { navigate } from "gatsby"

function QuotationForm() {
  const { register, handleSubmit } = useForm()

  const onSubmit = data => {
    data["api_key"] = process.env.GATSBY_WEFACT_API_KEY
    fetch(process.env.GATSBY_WEFACT_GATEWAY_URL, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data === "Quotation sent") {
          navigate("/thankyou")
        }
      })
      .catch(error => console.error("Error:", error))
  }

  const formBorderStyle = { borderRadius: 0 }

  return <Form className="mx-4 my-5" method="post" onSubmit={handleSubmit(onSubmit)}>

    <Form.Group>
      <Form.Label className="head-text">Voornaam (verplicht)</Form.Label>
      <Form.Control id="Initials" name="Initials" ref={register} style={formBorderStyle} type="text" required/>
    </Form.Group>

    <Form.Group>
      <Form.Label className="head-text">Achternaam (verplicht)</Form.Label>
      <Form.Control id="SurName" name="SurName" ref={register} style={formBorderStyle} type="text" required/>
    </Form.Group>

    <Form.Group>
      <Form.Label className="head-text">Telefoonnummer</Form.Label>
      <Form.Control id="MobileNumber" name="MobileNumber" ref={register} style={formBorderStyle} type="text"/>
    </Form.Group>

    <Form.Group>
      <Form.Label className="head-text">E-mailadres (verplicht)</Form.Label>
      <Form.Control id="EmailAddress" name="EmailAddress" ref={register} style={formBorderStyle} type="email" required/>
    </Form.Group>

    <Form.Group>
      <Form.Label className="head-text">Straat en huisnummer (verplicht)</Form.Label>
      <Form.Control id="Address" name="Address" ref={register} style={formBorderStyle} type="text" required/>
    </Form.Group>

    <Form.Group>
      <Form.Label className="head-text">Stad (verplicht)</Form.Label>
      <Form.Control id="City" name="City" ref={register} style={formBorderStyle} type="text" required/>
    </Form.Group>

    <Form.Group>
      <Form.Label className="head-text">Omschrijving (verplicht)</Form.Label>
      <Form.Control id="Description" name="Description" ref={register} as="textarea" rows="5" style={formBorderStyle} type="text" required/>
    </Form.Group>

    <Button className="head-text mt-3" style={{backgroundColor: "#142D56", borderRadius: 0}} type="submit">
      OFFERTE AANVRAAG VERSTUREN
    </Button>
  </Form>
}
export default QuotationForm;