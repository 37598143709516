import React from "react"
import window from "global"
import { Container, Row, Col} from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/SEO"
import Layout from "../components/layout"
import QuotationForm from "../components/quotationForm"

function Quotation() {
  const data = useStaticQuery(graphql`
    query quotationQuery {
      BackgroundImage: file(relativePath: {eq: "CONTACT/LOSSE AFBEELDINGEN/Background_Contact.png"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      SmallBackgroundImage: file(relativePath: {eq: "CONTACT/LOSSE AFBEELDINGEN/Background_Contact_Small.png"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const [hasMounted, setHasMounted] = React.useState(false)
  const [width, setWidth] = React.useState(window.innerWidth)
  const updateWidth = () => {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  React.useEffect(() => {
    setHasMounted(true)
  }, [width])

  if (!hasMounted) {
    return null
  }

  let backgroundImage = data.BackgroundImage

  let coverText = (
    <Col
      className={"head-text text-center"}
      style={{ color: "white", marginTop: "30vh" }}
      md={{ span: 10, offset: 1 }}>
      <h1 style={{ fontSize: "4em" }}>VRAAG NAAR DE MOGELIJKHEDEN</h1>
    </Col>
  )
  if (width <= 1250) {
    coverText = (
      <Col
        className={"head-text text-center"}
        style={{ color: "white", marginTop: "-60vh", zIndex: -1 }}
        md={{ span: 10, offset: 1 }}>
        <h1 style={{ fontSize: "7vw" }}>VRAAG NAAR DE MOGELIJKHEDEN</h1>
      </Col>
    )
    if (width <= 600){
      backgroundImage = data.SmallBackgroundImage;
    }
  }

  return (
    <>
      <SEO title="Offerte aanvraag" />
      <Layout
        width={width}
        backgroundImage={backgroundImage.childImageSharp.fluid}
        coverText={coverText}
        bottomBannerBar={false}
        backgroundImageStyles={{ backgroundColor: "black", backgroundPosition: "right", height: "100vh" }}>
        <Container fluid>
          <Row>
            <Col className="contactForm" xs={{span: 12}} lg={{span: 6, offset: 3}} style={{backgroundColor: "#C3B69C", color: "#142D56", marginBottom: "-7vh", marginTop:"-20vh"}}>
              <QuotationForm/>
            </Col>
          </Row>
          <Row style={{height: "150px", backgroundColor: "#1F2D53"}}></Row>
        </Container>
      </Layout>
    </>
  )
}

export default Quotation
